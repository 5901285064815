// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SkVjT8DtYUY5-syICX4dW{/*!*/}.sXjtfkQofXsxlqmrq3hOJ{height:100vh;background:#09193B;position:relative}.sXjtfkQofXsxlqmrq3hOJ._3aYnWo8UUksD4nZrxjXMJG{background:#951616}\n", ""]);
// Exports
exports.locals = {
	"cls1": "SkVjT8DtYUY5-syICX4dW",
	"cls2": "sXjtfkQofXsxlqmrq3hOJ SkVjT8DtYUY5-syICX4dW",
	"variant-dark": "_3aYnWo8UUksD4nZrxjXMJG"
};
module.exports = exports;
