// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3WHHEX7yM2sp35sfocyofG{/*!*/}._1DrMItvac7B0YFGjdqlUiI{position:absolute;width:4px;height:4px;background:rgba(255,255,255,0.2);border-radius:5px}._1DrMItvac7B0YFGjdqlUiI:nth-child(1n){animation:LluiFXpSN1I5huFcdZVQy 6s linear 6s infinite;animation-delay:-6s}._1DrMItvac7B0YFGjdqlUiI:nth-child(2n){animation:LluiFXpSN1I5huFcdZVQy 9s linear 5s infinite;animation-delay:-5s}._1DrMItvac7B0YFGjdqlUiI:nth-child(3n){animation:LluiFXpSN1I5huFcdZVQy 4s linear 6s infinite;animation-delay:-6s}._1DrMItvac7B0YFGjdqlUiI:nth-child(4n){animation:LluiFXpSN1I5huFcdZVQy 6s linear 7s infinite;animation-delay:-7s}._1DrMItvac7B0YFGjdqlUiI:nth-child(5n){animation:LluiFXpSN1I5huFcdZVQy 4s linear 8s infinite;animation-delay:-8s}@keyframes LluiFXpSN1I5huFcdZVQy{0%{transform:scale(1, 1);background:rgba(255,255,255,0.2);animation-timing-function:ease-in}60%{transform:scale(0.8, 0.8);background:#fff;animation-timing-function:ease-out}80%{background:rgba(255,255,255,0.2);transform:scale(1, 1)}100%{background:rgba(255,255,255,0.2);transform:scale(1, 1)}}\n", ""]);
// Exports
exports.locals = {
	"cls1": "_3WHHEX7yM2sp35sfocyofG",
	"cls2": "_1DrMItvac7B0YFGjdqlUiI _3WHHEX7yM2sp35sfocyofG",
	"twinkle": "LluiFXpSN1I5huFcdZVQy"
};
module.exports = exports;
